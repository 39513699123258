import { subYears } from 'date-fns';

import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import { DatesRange } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import { convertUTCToUserTimezone } from 'utils/DateUtils';

interface PresetTimeRange {
  label: string;
  isSelected: (datesRange: DatesRange) => boolean;
  onClick: () => void;
}

type TimePeriod = 'days' | 'months' | 'years'

function getStartOfDayTime(date?: Date): number {
  return startOfDay(date || new Date()).getTime();
}

function getPeriodDate(periodBack: number, periodType: TimePeriod): Date {
  const periodMapper: Record<TimePeriod, (date: Date, amount: number) => Date> = {
    days: (date, amount) => subDays(date, amount - 1),
    months: subMonths,
    years: subYears,
  };

  return periodMapper[periodType](new Date(), periodBack);
}

export default function getPresetTimeRanges(
  setDatesRange: ({ from, to }: { from: Date; to: Date }) => void,
): PresetTimeRange[] {
  function changeDatesRangeByPeriodBack(periodBack: number, periodType: TimePeriod): void {
    const fromDate = getPeriodDate(periodBack, periodType);
    const payload = { from: fromDate, to: new Date() };
    setDatesRange(payload);
  }

  function getIsSelectedForLastPeriod(periodBack: number, periodType: TimePeriod) {
    return (datesRange: DatesRange) => {
      const todayStart = getStartOfDayTime();
      const periodAgoStart = getStartOfDayTime(getPeriodDate(periodBack, periodType));

      const toStart = getStartOfDayTime(convertUTCToUserTimezone(datesRange.to));
      const fromStart = getStartOfDayTime(convertUTCToUserTimezone(datesRange.from));

      return todayStart === toStart && periodAgoStart === fromStart;
    };
  }

  return [
    {
      label: 'Today',
      isSelected: getIsSelectedForLastPeriod(1, 'days'),
      onClick: () => changeDatesRangeByPeriodBack(1, 'days'),
    }, {
      label: 'Last 7 Days',
      isSelected: getIsSelectedForLastPeriod(7, 'days'),
      onClick: () => changeDatesRangeByPeriodBack(7, 'days'),
    }, {
      label: 'Last 28 Days',
      isSelected: getIsSelectedForLastPeriod(28, 'days'),
      onClick: () => changeDatesRangeByPeriodBack(28, 'days'),
    }, {
      label: 'Last 3 Months',
      isSelected: getIsSelectedForLastPeriod(3, 'months'),
      onClick: () => changeDatesRangeByPeriodBack(3, 'months'),
    }, {
      label: 'Last Year',
      isSelected: getIsSelectedForLastPeriod(1, 'years'),
      onClick: () => changeDatesRangeByPeriodBack(1, 'years'),
    },
  ];
}
